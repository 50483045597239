import React from 'react';

export default () => (
  <>
    <a
      href="https://wa.me/573126922329?text=Puedes+contactarnos+tambi%C3%A9n+por+los+siguientes+medios.%0D%0Acorreo%3A+logistica%40ingasdelcaribe.com%0D%0Atel%C3%A9fono%3A+3126922329"
      className="btn wa-link"
      target="_blank"
      rel="noreferrer"
    >
      <span className="text">
        <i className="fab fa-whatsapp wa-icon" />
        Contactanos
      </span>
    </a>
  </>
);
