import React from 'react';
import Header from './Header';
import ProductsAndServices from './Portfolio';
import CallToAction from './CallToAction';
import WhatsappButton from './WhatsappButton';
import Footer from './Footer';

export default () => (
  <>
    <Header title={null} defaultDescription={false} defaultCallToAction={null} />
    <ProductsAndServices />
    <CallToAction />
    <WhatsappButton />
    <Footer />
  </>
);
