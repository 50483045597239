import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Navbar from './Navbar';
import MobileNav from './MobileNav';
import bgHeader from '../../assets/images/bg-header-final.png';
import StartupScript from '../packs/website/startup-script';

const titleHeader = (title) => {
  if (title) {
    return (
      <h1 className="big color-white text-center" data-aos="fade-down" data-aos-delay="0">
        <span>
          Título principal
          <br />
          va aquí
        </span>
      </h1>
    );
  }

  return null;
};

const descriptionHeader = (defaultDescription = true) => {
  if (defaultDescription) {
    return (
      <div className="mw-620 mx-auto mt-35 f-22 color-white op-7 text-center text-adaptive" data-aos="fade-down" data-aos-delay="250">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam orci elit,
        hendrerit id iaculis in, convallis a lorem. Suspendisse imperdiet scelerisque leo,
        ac laoreet lacus cursus eu.
        Sed placerat urna et orci luctus.
      </div>
    );
  }

  return null;
};

const callToActionHeader = (defaultCallToAction) => {
  if (defaultCallToAction) {
    return (
      <>
        <div className="mw-620 mx-auto mt-35 f-22 text-center text-adaptive" data-aos="fade-down" data-aos-delay="250">
          <Link to="/cotizacion" className="btn action-1 lg">
            Cotiza
          </Link>
        </div>
        <div className="color-white op-3 text-center mt-35" data-aos="fade-down" data-aos-delay="750">
          <span>
            Te contactaremos una vez recibamos tu información
          </span>
        </div>
      </>
    );
  }

  return null;
};

const Header = function Header(props) {
  const { title, defaultDescription, defaultCallToAction } = props;

  useEffect(() => {
    StartupScript();
  }, []);

  return (
    <>
      <MobileNav />
      { /* Header 2 */ }
      <header className="pt-190 pb-120 header_2" data-bg-src={bgHeader} data-bg-src-2x={bgHeader}>
        <Navbar />
        <div className="container">
          <div className="mb-3 logo color-white d-block d-xl-none text-center logo_mobile" data-aos="fade-down" data-aos-delay="0">
            Ingas Del Caribe
          </div>
          { titleHeader(title) }
          { descriptionHeader(defaultDescription) }
          { callToActionHeader(defaultCallToAction) }
        </div>
      </header>
    </>
  );
};

Header.propTypes = {
  title: PropTypes.string,
  defaultDescription: PropTypes.bool,
  defaultCallToAction: PropTypes.bool,
};

Header.defaultProps = {
  title: '',
  defaultDescription: true,
  defaultCallToAction: true,
};

export default Header;
