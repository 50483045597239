import React from 'react';
import { Link } from 'react-router-dom';
import mainPorductImg from '../../assets/images/pipes.jpg';

class Portfolio extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      services: [],
    };
  }

  componentDidMount() {
    this.portfolio('api/v1/products', 'products');
    this.portfolio('api/v1/services', 'services');
  }

  portfolio(resourcePath, type) {
    const url = resourcePath;
    const { history } = this.props;

    fetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Error in the response');
      })
      .then((response) => {
        const modifiedResponse = response.map((resource) => {
          const r = resource;
          r.type = type;
          return r;
        });
        this.setState({ [type]: modifiedResponse });
      })
      .catch(() => (history.push('/')));
  }

  render() {
    const { products, services } = this.state;
    let portfolio = [...products, ...services];

    portfolio = portfolio.map((resource, i) => {
      const r = resource;
      r.key = i;
      return r;
    });

    const allPortfolio = portfolio.map((p, index) => (
      <div key={p.key} className="mt-50 col-lg-10 col-md-10 col-sm-10 d-flex align-items-start flex-wrap flex-md-nowrap product" data-aos="fade-left" data-aos-delay={index * 350}>
        <Link to={`/${p.type}/${p.id}`} className="link img_link">
          <img
            src={p.image_url}
            srcSet={p.image_url}
            // Remove the two lines below when S3 is configured to save the images
            // srcSet={tmpImage}
            alt={p.name}
            className="w-350 mw-100 img-fluid flex-shrink-0 radius10"
          />
        </Link>
        <div className="mt-3 ml-md-4 relative pb-10 inner">
          <Link to={`/${p.type}/${p.id}`} className="link color-main f-14 text-uppercase semibold sp-20 title">
            {p.name}
          </Link>
          <div className="mt-1 color-heading text-adaptive">
            {p.description}
          </div>
        </div>
      </div>
    ));

    const emptyPortfolio = (
      <div className="vw-100 vh-50 d-flex align-items-center justify-content-center">
        <h4>
          No existen productos o servicios en este momento.
        </h4>
      </div>
    );

    return (
      <>
        <section id="productos-y-servicios" className="pt-100 bg-light ecommerce_1">
          <div className="container px-xl-0">
            <div className="row justify-content-center text-center text-lg-left main_info">
              <div className="col-lg-8">
                <h2 className="mb-25" data-aos="fade-down" data-aos-delay="0">
                  Productos y Servicios
                </h2>
                <div className="row" data-aos="fade-down" data-aos-delay="0">
                  <div className="col-xl-9 col-lg-10 color-heading text-adaptive">
                    Nuestros productos y servicios te brindan la confianza que necesitas.
                    Respaldados por 20 años de experiencia en el mercado.
                  </div>
                </div>
                {/* <Link
                  to="/productos"
                  className="btn mt-60 lg action-1 px-xl-4"
                  data-aos="fade-down" data-aos-delay="350">
                  Consultalos Todos
                </Link> */}
              </div>
              <div className="mt-50 mt-lg-0 col-lg-4 d-none d-sm-inline" data-aos="fade-down" data-aos-delay="350">
                <img src={mainPorductImg} srcSet={mainPorductImg} alt="" className="img-fluid radius10" />
              </div>
            </div>
            <div className="mt-30 mt-md-4 row justify-content-center products">
              {portfolio.length > 0 ? allPortfolio : emptyPortfolio}
            </div>
          </div>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 256" preserveAspectRatio="none" className="division">
            <path fill="#ffffff" fillOpacity="1" d="M0,256L720,192L1440,46L1440,256L720,256L0,256Z" />
          </svg>
        </section>
      </>
    );
  }
}

export default Portfolio;
