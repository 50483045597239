import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Header from './Header';
import Footer from './Footer';

const Product = (props) => {
  const [source, setSource] = useState({
    id: 0,
    name: '',
    description: '',
    image_url: '',
    detailed_description: '',
  });

  const {
    match: {
      params: {
        id,
        resource,
      },
    },
  } = props;

  useEffect(() => {
    const url = `/api/v1/${resource}/${id}`;

    fetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }

        throw new Error(`Error al obtener el ${resource}`);
      })
      .then((response) => (setSource(response)))
      .catch((error) => (props.history.push('/')));
  }, []);

  return (
    <>
      <Header defaultCallToAction={false} defaultDescription={false} title="Productos y Servicios" />
      <section className="bg-light blog_1">
        <div className="container px-xl-0">
          <div className="row justify-content-between">
            <div className="col-lg-6 col-xl-5 d-flex justify-content-center pt-100 pb-lg-5">
              <img src={source.image_url} srcSet={source.image_url} alt="" className="w-full product-custom-img" data-aos="fade-down" data-aos-delay="0" />
            </div>
            <div className="col-lg-6 pt-100 pb-100">
              <div className="f-14 semibold text-uppercase sp-20 mb-15 d-flex color-heading" data-aos="fade-down" data-aos-delay="250">
                { resource }
              </div>
              <h3 className="mb-25" data-aos="fade-down" data-aos-delay="500">
                { source.name }
              </h3>
              <div className="mb-30 color-heading f-18 text-adaptive" data-aos="fade-down" data-aos-delay="750">
                { source.description }
              </div>
              <div
                className="mb-70 color-heading f-18 text-adaptive"
                data-aos="fade-down"
                data-aos-delay="1000"
                dangerouslySetInnerHTML={{ __html: source.detailed_description }}
              />
              <div className="d-sm-flex justify-content-between" data-aos="fade-down" data-aos-delay="1250">
                <Link to="/" className="mb-4 mb-sm-0 btn action-1 f-16 sm">
                  Regresar
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

Product.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      resource: PropTypes.string,
    }),
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

Product.defaultProps = {
  match: {
    params: {
      id: '',
      resource: '',
    },
  },
  history: {},
};

export default Product;
