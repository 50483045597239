import React from 'react';
import Header from './Header';
import Footer from './Footer';
import ContactForm from './forms/ContactForm';
import WhatsappButton from './WhatsappButton';

const Contact = function Contact() {
  return (
    <>
      <Header
        title={null}
        defaultDescription={false}
        defaultCallToAction={false}
      />
      <WhatsappButton />
      <ContactForm />
      <Footer />
    </>
  );
};

export default Contact;
