import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from '../components/Home';
import Products from '../components/Products';
import Contact from '../components/Contact';
import Quote from '../components/Quote';
import Product from '../components/Product';
import WhoWeAre from '../components/WhoWeAre';

export default (
  <Router>
    <Switch>
      <Route path="/" exact component={Home} />
      {/* <Route path="/productos" exact component={Products} /> */}
      <Route path="/contacto" exact component={Contact} />
      <Route path="/cotizacion" exact component={Quote} />
      <Route path="/quienes-somos" exact component={WhoWeAre} />
      <Route path="/:resource/:id" exact component={Product} />
    </Switch>
  </Router>
);
