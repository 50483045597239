import React from 'react';
import Header from './Header';
import QuoteForm from './forms/QuoteForm';
import Footer from './Footer';

const Quote = function Quote() {
  return (
    <>
      <Header
        title="Cotiza tus Productos"
        defaultDescription={false}
        defaultCallToAction={false}
      />
      <QuoteForm />
      <Footer />
    </>
  );
};

export default Quote;
