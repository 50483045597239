import React from 'react';
import Header from './Header';
import Footer from './Footer';
import WhatsappButton from './WhatsappButton';

const WhoWeAre = function WhoWeAre() {
  return (
    <>
      <Header
        title={null}
        defaultDescription={false}
        defaultCallToAction={false}
      />
      <section className="pt-80 pb-100 bg-light text-center contact_2">
        <div className="container px-xl-0">
          <h2 className="mt-20 mb-40 small" data-aos="fade-down" data-aos-delay="250">
            Quienes Somos
          </h2>
          <div className="mb-30 color-heading f-18 text-adaptive" data-aos="fade-down" data-aos-delay="750">
            Somos una empresa que brinda soluciones en diseño, instalación, mantenimiento y
            suministro de elementos de redes de gases para el sector hospitalario e
            industrial; prestamos servicios de calibración y mantenimiento a instrumentos
            de medición y de válvulas de seguridad, contamos con una experiencia de más de
            20 años en la venta y distribución de gases industriales y especiales.
          </div>
        </div>
      </section>
      <WhatsappButton />
      <Footer />
    </>
  );
};

export default WhoWeAre;
