import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const QuoteForm = function QuoteForm(props) {
  const [listOfProductsNames, setListOfProductsNames] = useState([]);
  const [productsSelect, setProductsSelect] = useState({ value: 'Selecciona un producto' });
  const [quotes, setQuotes] = useState([]);

  const productsOptions = () => {
    const options = listOfProductsNames.map((o) => (
      <option key={o.id} value={o.name}>
        {o.name}
      </option>
    ));

    return options;
  };

  const addProductToQuote = (resource) => {
    const quoteAry = new Array(resource);

    if (!quotes.includes(resource)) {
      setQuotes(quotes.concat(quoteAry));
    }
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setProductsSelect({ value });
    addProductToQuote(value);
  };

  useEffect(() => {
    const url = '/api/v1/products/names_list';

    fetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }

        throw new Error('Error obteniendo la lista de nombres de producto');
      })
      .then((response) => {
        setListOfProductsNames(response.products_list);
      })
      .catch(() => {
        props.history.push('/');
      });
  }, []);

  return (
    <>
      <section className="pt-80 pb-100 bg-light text-center contact_2">
        <div className="container px-xl-0">
          <form action="form-handler.php" method="post">
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-8 col-md-10">
                <h2 className="mt-20 mb-40 small" data-aos="fade-down" data-aos-delay="250">
                  Selecciona los productos en los que estas interesado
                </h2>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-xl-4 col-lg-5 col-md-6 col-sm-7" data-aos="fade-down" data-aos-delay="750">
                <input type="text" name="name" placeholder="Nombre" required="required" className="input mb-30 w-full lg border-gray focus-action-1 color-heading placeholder-heading text-center text-sm-left" />
              </div>
              <div className="col-xl-4 col-lg-5 col-md-6 col-sm-7" data-aos="fade-down" data-aos-delay="750">
                <input type="email" name="email" placeholder="Correo" required="required" className="input mb-30 w-full lg border-gray focus-action-1 color-heading placeholder-heading text-center text-sm-left" />
              </div>
              <div className="col-12" />
              <div className="col-xl-4 col-lg-5 col-md-6 col-sm-7" data-aos="fade-down" data-aos-delay="750">
                <input type="text" name="phone" placeholder="Teléfono" required="required" className="input mb-30 w-full lg border-gray focus-action-1 color-heading placeholder-heading text-center text-sm-left" />
              </div>
              <div className="col-xl-4 col-lg-5 col-md-6 col-sm-7" data-aos="fade-down" data-aos-delay="750">
                <input type="text" name="company" placeholder="Empresa" required="required" className="input mb-30 w-full lg border-gray focus-action-1 color-heading placeholder-heading text-center text-sm-left" />
              </div>
              <div className="col-12" />
              <div className="col-xl-4 col-lg-5 col-md-6 col-sm-7" data-aos="fade-down" data-aos-delay="750">
                <select
                  className="input mb-30 w-full lg border-gray focus-action-1 color-heading placeholder-heading text-center text-sm-left"
                  name="product"
                  onChange={handleChange}
                  required="required"
                  value={productsSelect.value}
                >
                  <option value="Selecciona un producto" disabled> Selecciona un producto </option>
                  {productsOptions()}
                </select>
              </div>
              <div className="col-xl-4 col-lg-5 col-md-6 col-sm-7" data-aos="fade-down" data-aos-delay="750">
                <select name="service" required="required" className="input mb-30 w-full lg border-gray focus-action-1 color-heading placeholder-heading text-center text-sm-left">
                  <option> Servicio 1 </option>
                  <option> Servicio 2 </option>
                  <option> Servicio 3 </option>
                </select>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="f-14 semibold text-uppercase sp-20 color-heading mb-40" data-aos="fade-down" data-aos-delay="0">
                Productos y Servicios seleccionados
              </div>
              <div className="col-12" />
              <div className="col-xl-4 col-lg-5 col-md-6 col-sm-7" data-aos="fade-down" data-aos-delay="750">
                <p className="color-heading f-22"> Product 1 </p>
              </div>
              <div className="col-xl-4 col-lg-5 col-md-6 col-sm-7" data-aos="fade-down" data-aos-delay="750">
                <span className="rm-product">
                  <i className="fas fa-trash-alt fa-2x" />
                </span>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-xl-2 col-lg-3 col-md-4 col-sm-5" data-aos="fade-down" data-aos-delay="1000">
                <button className="btn mb-30 mt-5 lg w-full action-1" type="button">
                  Cotizar
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

QuoteForm.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

QuoteForm.defaultProps = {
  history: {},
};

export default QuoteForm;
