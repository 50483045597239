import React from 'react';
import Header from './Header';
import imgTemp1 from '../../assets/images/showcase_2_img_1.jpg';
import Footer from './Footer';

class Products extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      services: [],
    };
  }

  componentDidMount() {
    const url = 'api/v1/products';
    const { history } = this.props;

    fetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }

        throw new Error('Error receiving the response');
      })
      .then((response) => this.setState({ products: response }))
      .catch(() => (history.push('/')));

    this.services();
  }

  services() {
    const url = 'api/v1/services';
    const { history } = this.props;

    fetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }

        throw new Error('Error reciving the services info');
      })
      .then((response) => this.setState({ services: response }))
      .catch(() => (history.push('/')));
  }

  render() {
    const { products, services } = this.state;
    const title = '';
    const productsLayout = products.map((p) => (
      <div key={p.id} className="col-md-4 col-sm-6" data-aos="fade-down" data-aos-delay="0">
        <a href="/" className="mt-50 link color-main">
          <img
            src={p.image_url}
            srcSet={p.image_url}
            alt={p.name}
            className="img-fluid radius10"
          />
          <div className="mt-20 mb-15 f-22 title">
            { p.name }
          </div>
          <div className="color-heading f-14 semibold text-uppercase sp-20">
            Mas información
          </div>
        </a>
      </div>
    ));

    const servicesLayout = services.map((s) => (
      <div key={s.id} className="col-md-4 col-sm-6" data-aos="fade-down" data-aos-delay="0">
        <a href="/" className="mt-50 link color-main">
          <img
            src={s.image_url}
            srcSet={s.image_url}
            alt={s.name}
            className="img-fluid radius10"
          />
          <div className="mt-20 mb-15 f-22 title">
            { s.name }
          </div>
          <div className="color-heading f-14 semibold text-uppercase sp-20">
            Mas información
          </div>
        </a>
      </div>
    ));

    return (
      <>
        <Header
          title={title}
          defaultDescription={false}
          defaultCallToAction={false}
        />
        <section className="pt-105 pb-90 bg-light text-center showcase_2">
          <div className="container px-xl-0">
            <div className="row justify-content-center">
              <div className="col-xl-8 col-lg-10">
                <h2 className="small" data-aos="fade-down" data-aos-delay="0">
                  Productos
                </h2>
                <div className="mt-15 mb-25 f-22 color-heading text-adaptive" data-aos="fade-down" data-aos-delay="250">
                  Cada producto está específicamente diseñado pensado en tus necesidades.
                </div>
              </div>
            </div>
            <div className="row">
              { productsLayout }
              <div className="col-md-4 col-sm-6" data-aos="fade-down" data-aos-delay="250">
                <a href="/" className="mt-50 link color-main">
                  <img src={imgTemp1} srcSet={imgTemp1} alt="" className="img-fluid radius10" />
                  <div className="mt-20 mb-15 f-22 title">
                    Producto 3
                  </div>
                  <div className="color-heading f-14 semibold text-uppercase sp-20">
                    Short description
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="pt-105 pb-90 bg-light text-center showcase_2">
          <div className="container px-xl-0">
            <div className="row justify-content-center">
              <div className="col-xl-8 col-lg-10">
                <h2 className="small" data-aos="fade-down" data-aos-delay="0">
                  Servicios
                </h2>
              </div>
            </div>
            <div className="row">
              { servicesLayout }
            </div>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}

export default Products;
