import React, { useEffect } from 'react';
import AOS from 'aos';
import Routes from '../routes/Index';
import IngasScripts from '../packs/website/custom';

function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
    IngasScripts();
  }, []);

  return (
    <>{Routes}</>
  );
}

export default App;
