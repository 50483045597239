import React from 'react';
import SuccessMessage from '../notifications/Success';

class ContactForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      contact: {
        message: '',
        company: '',
        email: '',
      },
      responseMsg: '',
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(event) {
    event.persist();

    this.setState((prev) => (
      Object.assign(prev.contact, { [event.target.name]: event.target.value })
    ));
    console.log(this.state);
  }

  onSubmit(event) {
    event.preventDefault();
    const url = 'api/v1/contacts';
    const { contact } = this.state;
    const body = {
      contact: Object.assign(contact, { message: contact.message.replace(/\n/g, '<br> <br>') }),
    };
    const token = document.querySelector('meta[name="csrf-token"]').content;

    fetch(url, {
      method: 'POST',
      headers: {
        'X-CSRF-TOKEN': token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }

        throw new Error('Response from the server was not ok.');
      })
      .then((response) => {
        console.log(`Response from server: ${JSON.stringify(response)}`);
        this.setState({
          contact: {
            message: '',
            company: '',
            email: '',
          },
          responseMsg: response.msg,
        });
      })
      .catch((error) => console.log(error.message));
  }

  render() {
    const { responseMsg, contact } = this.state;

    return (
      <>
        <section className="pt-80 pb-100 bg-light text-center contact_2">
          <SuccessMessage message={responseMsg} />
          <div className="container px-xl-0">
            <form onSubmit={this.onSubmit}>
              <div className="row justify-content-center">
                <div className="col-xl-6 col-lg-8 col-md-10">
                  <div className="f-14 semibold text-uppercase sp-20 color-heading" data-aos="fade-down" data-aos-delay="0">
                    Empecemos
                  </div>
                  <h2 className="mt-20 mb-40 small" data-aos="fade-down" data-aos-delay="250">
                    Queremos entender mejor tus necesidades
                  </h2>
                  <div data-aos="fade-down" data-aos-delay="0">
                    <textarea
                      name="message"
                      placeholder="Escribe tu mensaje aquí"
                      required
                      className="input mb-30 w-full lg radius10 border-gray focus-action-1 color-heading placeholder-heading text-left"
                      onChange={this.onChange}
                      value={contact.message}
                    />
                  </div>
                </div>
                <div className="col-12" />
                <div className="col-xl-6 col-lg-8 col-md-10 col-sm-12" data-aos="fade-down" data-aos-delay="0">
                  <input
                    type="text"
                    name="company"
                    placeholder="El nombre de tu empresa"
                    required
                    className="input mb-30 w-full lg border-gray focus-action-1 color-heading placeholder-heading text-center text-sm-left"
                    onChange={this.onChange}
                    value={contact.company}
                  />
                </div>
                <div className="col-12" />
                <div className="col-xl-4 col-lg-5 col-md-6 col-sm-7" data-aos="fade-down" data-aos-delay="0">
                  <input
                    type="email"
                    name="email"
                    placeholder="Tu correo"
                    required
                    className="input mb-30 w-full lg border-gray focus-action-1 color-heading placeholder-heading text-center text-sm-left"
                    onChange={this.onChange}
                    value={contact.email}
                  />
                </div>
                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-5" data-aos="fade-down" data-aos-delay="0">
                  <button type="submit" className="btn mb-30 lg w-full action-1">
                    Enviar
                  </button>
                </div>
              </div>
            </form>
            <div className="row justify-content-center">
              <div className="col-xl-1" />
              <div className="col-xl-3 col-md-4 col-sm-8 mt-50" data-aos="fade-down" data-aos-delay="0">
                <div className="mb-10 f-14 semibold text-uppercase sp-20 title">
                  Barranquilla, CO
                </div>
                <div className="color-heading text-adaptive">
                  Carrera 50B No. 43 - 15
                </div>
              </div>
              <div className="col-xl-1" />
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default ContactForm;
