import React from 'react';
import { Link } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';

export default () => (
  <>
    {/* Footer 1 */}
    <footer className="pt-75 pb-65 bg-light text-center footer_1 ft-bg-color">
      <div className="container px-xl-0">
        <div className="row justify-content-between align-items-center lh-40 links">
          <div className="col-lg-4 col-sm-6 text-sm-right text-lg-left order-1 order-lg-0" data-aos="fade-down" data-aos-delay="350">
            <NavHashLink
              smooth
              to="/#productos-y-servicios"
              className="link mx-15 color-main"
              role="link"
            >
              Productos y Servicios
            </NavHashLink>
            <Link
              to="/quienes-somos"
              className="link mx-15 color-main"
            >
              Quienes Somos
            </Link>
            {/* <a href="/" className="link mx-15 color-main">
              Politicas
            </a> */}
          </div>
          <div className="mb-10 mb-lg-0 col-lg-auto order-0" data-aos="fade-down" data-aos-delay="0">
            <Link to="/" className="link logo color-main">
              Ingas del Caribe
            </Link>
          </div>
          <div className="col-lg-4 col-sm-6 text-sm-left text-lg-center order-2 order-lg-0" data-aos="fade-down" data-aos-delay="350">
            <Link
              to="/contacto"
              className="link mx-15 color-main"
            >
              Contacto
            </Link>
            <a href="https://www.instagram.com/ingasdelcaribe/" target="_blank" rel="noopener noreferrer" className="mx-35 link color-main">
              <i className="fab fa-instagram" />
            </a>
            <a href="https://www.facebook.com/Ingas-Del-Caribe-100120568805714/" target="_blank" rel="noopener noreferrer" className="mx-35 link color-main">
              <i className="fab fa-facebook-square" />
            </a>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="mt-10 col-xl-4 col-lg-5 col-md-6 col-sm-8">
            <div className="color-heading text-adaptive">
              Todos los derechos reservados 2020
            </div>
          </div>
        </div>
      </div>
    </footer>
  </>
);
