import React from 'react';
import { Link } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';

const Navbar = () => (
  <nav className="header_menu_2 transparent pt-30 pb-30 mt-20">
    <div className="container px-xl-0">
      <div className="row justify-content-between align-items-baseline">
        <div className="col-md-4" data-aos="fade-down" data-aos-delay="750">
          <div>
            <Link
              to="/"
              className="logo link color-white"
              role="link"
            >
              Ingas Del Caribe
            </Link>
          </div>
        </div>
        <div className="col-md-6 d-flex justify-content-end align-items-center" data-aos="fade-down" data-aos-delay="750">
          <NavHashLink
            smooth
            to="/#productos-y-servicios"
            className="link color-white f-18 mx-15"
            role="link"
          >
            Productos y Servicios
          </NavHashLink>
          <Link
            to="/contacto"
            className="link color-white f-18 mx-15"
            role="link"
          >
            Contácto
          </Link>
          <Link
            to="/quienes-somos"
            className="link color-white f-18 mx-15"
            role="link"
          >
            Quíenes Somos
          </Link>
        </div>
      </div>
    </div>
  </nav>
);

export default Navbar;
