import React from 'react';
import { Link } from 'react-router-dom';

export default () => (
  <>
    { /* Call to action 1 */ }
    <section className="pt-110 pb-50 bg-light text-center call_to_action_1">
      <div className="container px-xl-0">
        <div className="row justify-content-center">
          <div className="col-xl-8 col-lg-10">
            <h2 data-aos="fade-down" data-aos-delay="0">
              Estás Interesado En Nuestros Productos?
            </h2>
          </div>
          <div className="col-xl-7 col-lg-9 col-md-10">
            <div className="mt-20 mb-60 f-22 color-heading text-adaptive description" data-aos="fade-down" data-aos-delay="350">
              Te ofrecemos la opción de cotizar nuestros productos y servicios de una forma ágil.
            </div>
            <div data-aos="fade-down" data-aos-delay="700">
              <Link
                to="/contacto"
                className="btn mb-30 lg action-1"
              >
                Cotiza Aquí
              </Link>
            </div>
            <div className="color-heading text-adaptive" data-aos="fade-down" data-aos-delay="700">
              Una vez recibamos tu información nos podremos en contácto contigo
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
);
