import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Alert from 'react-bootstrap/Alert';

const SuccessMessage = (props) => {
  const { message } = props;
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (message) {
      setShow(true);
    }
  }, [message]);

  return (
    <Alert variant="success" show={show} onClose={() => { setShow(false); }} dismissible>
      { message }
    </Alert>
  );

  // return <button type="button" onClick={() => (setShow(true))}> Show Alert </button>;
};

SuccessMessage.propTypes = {
  message: PropTypes.string,
};

SuccessMessage.defaultProps = {
  message: '',
};

export default SuccessMessage;
