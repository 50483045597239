import React from 'react';
import { Link } from 'react-router-dom';

export default () => (
  // Navigation Mobile type 2
  <div>
    <a href="/" className="open_menu color-main bg-light radius_full">
      <i className="fas fa-bars lh-40" />
    </a>
    <div className="navigation_mobile bg-light type2">
      <button className="close_menu color-main btn btn-link" type="button">
        <i className="fas fa-times" />
      </button>
      <div className="px-40 pt-60 pb-60 text-center inner">
        <div>
          <Link
            to="/"
            className="f-heading f-22 link color-main mb-20"
            role="link"
          >
            Inicio
          </Link>
        </div>
        <div>
          <Link
            to="/#productos-y-servicios"
            className="f-12 link color-main op-7 text-uppercase sp-20 mb-20"
            role="link"
          >
            Productos y Servicios
          </Link>
        </div>
        <div>
          <Link
            to="/contacto"
            className="f-12 link color-main op-7 text-uppercase sp-20 mb-20"
            role="link"
          >
            Contácto
          </Link>
        </div>
        <div>
          <Link
            to="/quienes-somos"
            className="f-12 link color-main op-7 text-uppercase sp-20 mb-20"
            role="link"
          >
            Quienes Somos
          </Link>
        </div>
        <div className="socials mt-60">
          <a href="#_" target="_blank" className="link color-main f-18 mx-10">
            <i className="fab fa-facebook" />
          </a>
          <a href="#_" target="_blank" className="link color-main f-18 mx-10">
            <i className="fab fa-instagram" />
          </a>
        </div>
      </div>
    </div>
  </div>
);
